export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  lightVanilla: '#f4efe2',
  lightVanilla2: '#e2ded4',
  lightVanilla3: '#fcfaf6',
  darkVanilla: '#e4dfd0',
  grey: '#585653',
  greyText: '#424242',
  lightGrey: '#cecece',
  lightGrey2: '#c4c4c4',
  lightGrey3: '#f7f5f2',
  lightGrey4: '#f8f6f1',
  lightGrey5: '#f3f9e7',
  darkGreen: '#4C5032',
  green: '#495e35',
  green2: '#5D6F49',
  lightGreen: '#495e35',
  lightGreen2: '#585653',
  lightGreen3: '#495e35',
  lightGreen4: '#7d9068',
  lightGreen5: '#c2cfa8',
  brown: '#403B3A',
  redError: '#cb0606',
  orange: '#dd8153'
}

export const fonts = {
  sansSerif: 'Gotham',
  serif: 'AmericanaStd',
  handwriting: 'Mallorie'
}

export const fontSizes = {
  title: {
    xs: '3.2rem',
    lg: '4rem'
  },
  subtitle: {
    xs: '1.1rem',
    lg: '1rem'
  },
  paragraph: {
    lg: '1.2rem',
    xs: '1.2rem'
  }
}

export const spacing = {
  xxsmall: '1rem',
  xsmall: '2rem',
  small: '3rem',
  medium: '4rem',
  large: '8rem',
  xlarge: '12rem',
  xxlarge: '16rem'
}

export const durations = {
  fast: '0.3s',
  normal: '0.5s',
  slow: '1s'
}

export const header = {
  small: '64',
  normal: '100'
}

export const paddingX = {
  /* padding of the page */
  /* mobile, tablet, desktop */
  m: '5vw',
  t: '5vw',
  d: '3vw'
}

export default {
  colors,
  fonts,
  fontSizes,
  spacing,
  durations,
  header,
  paddingX
}
