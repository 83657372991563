import AmericanaStdBoldWoff from './AmericanaStd-Bold.woff'
import AmericanaStdBoldWoff2 from './AmericanaStd-Bold.woff2'
import AmericanaStdRomanWoff from './AmericanaStd-Roman.woff'
import AmericanaStdRomanWoff2 from './AmericanaStd-Roman.woff2'
import MallorieRegularWoff from './Mallorie-Regular.woff'
import MallorieRegularWoff2 from './Mallorie-Regular.woff2'
import GothamBookWoff from './Gotham-Book.woff'
import GothamBookWoff2 from './Gotham-Book.woff2'
import GothamBookItalicWoff from './Gotham-BookItalic.woff'
import GothamBookItalicWoff2 from './Gotham-BookItalic.woff2'
import GothamMediumWoff from './Gotham-Medium.woff'
import GothamMediumWoff2 from './Gotham-Medium.woff2'
import GothamMediumItalicWoff from './Gotham-MediumItalic.woff'
import GothamMediumItalicWoff2 from './Gotham-MediumItalic.woff2'

export const fonts = {
  AmericanaStdBoldWoff,
  AmericanaStdBoldWoff2,
  AmericanaStdRomanWoff,
  AmericanaStdRomanWoff2,
  MallorieRegularWoff,
  MallorieRegularWoff2,
  GothamBookWoff,
  GothamBookWoff2,
  GothamBookItalicWoff,
  GothamBookItalicWoff2,
  GothamMediumWoff,
  GothamMediumWoff2,
  GothamMediumItalicWoff,
  GothamMediumItalicWoff2
}

export default fonts
