import { css } from '@emotion/react'
import emotionReset from 'emotion-reset'
import { mediaMax } from '@/styles/mixins'
import { fonts as fontsFiles } from '@/fonts'
import 'swiper/css/bundle'

const styles = ({ fonts }) => {
  return css`
    @font-face {
      font-family: 'AmericanaStd';
      font-style: normal;
      font-weight: normal;
      src: url(${fontsFiles.AmericanaStdRomanWoff}) format("woff"), url(${fontsFiles.AmericanaStdRomanWoff2}) format("woff2");
      font-display: swap;
    }

    @font-face {
      font-family: 'AmericanaStd';
      font-style: normal;
      font-weight: bold;
      src: url(${fontsFiles.AmericanaStdBoldWoff}) format("woff"), url(${fontsFiles.AmericanaStdBoldWoff2}) format("woff2");
      font-display: swap;
    }

    @font-face {
      font-family: "Mallorie";
      font-style: normal;
      font-weight: normal;
      src: local('Mallorie'), local('Mallorie'), url(${fontsFiles.MallorieRegularWoff}) format("woff"), url(${fontsFiles.MallorieRegularWoff2}) format("woff2");
      font-display: swap;
    }

    @font-face {
      font-family: "Gotham";
      font-style: normal;
      font-weight: normal;
      src: url(${fontsFiles.GothamBookWoff}) format("woff"), url(${fontsFiles.GothamBookWoff2}) format("woff2");
      font-display: swap;
    }

    @font-face {
      font-family: "Gotham";
      font-style: italic;
      font-weight: normal;
      src: url(${fontsFiles.GothamBookItalicWoff}) format("woff"), url(${fontsFiles.GothamBookItalicWoff2}) format("woff2");
      font-display: swap;
    }

    @font-face {
      font-family: "Gotham";
      font-style: normal;
      font-weight: bold;
      src: url(${fontsFiles.GothamMediumWoff}) format("woff"), url(${fontsFiles.GothamMediumWoff2}) format("woff2");
      font-display: swap;
    }

    @font-face {
      font-family: "Gotham";
      font-style: italic;
      font-weight: bold;
      src: url(${fontsFiles.GothamMediumItalicWoff}) format("woff"), url(${fontsFiles.GothamMediumItalicWoff2}) format("woff2");
      font-display: swap;
    }

    ${emotionReset}

    *, *::after, *::before {
      box-sizing: border-box;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      font-smoothing: antialiased;
    }

    html, body {
      font-size: 10px;
      @media screen and (max-width: 250px) and (orientation: portrait) {
        font-size: 9px;
      }
    }

    html {
      overflow-x: hidden;
      scroll-behavior: smooth;
    }

    body {
      font-family: ${fonts.sansSerif};
    }
  `
}

export default styles
