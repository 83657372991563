exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-experience-js": () => import("./../../../src/templates/experience.js" /* webpackChunkName: "component---src-templates-experience-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-journal-js": () => import("./../../../src/templates/journal.js" /* webpackChunkName: "component---src-templates-journal-js" */),
  "component---src-templates-modular-page-js": () => import("./../../../src/templates/modular-page.js" /* webpackChunkName: "component---src-templates-modular-page-js" */),
  "component---src-templates-newsletter-js": () => import("./../../../src/templates/newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */),
  "component---src-templates-other-product-js": () => import("./../../../src/templates/other-product.js" /* webpackChunkName: "component---src-templates-other-product-js" */),
  "component---src-templates-other-products-js": () => import("./../../../src/templates/other-products.js" /* webpackChunkName: "component---src-templates-other-products-js" */),
  "component---src-templates-our-story-js": () => import("./../../../src/templates/our-story.js" /* webpackChunkName: "component---src-templates-our-story-js" */),
  "component---src-templates-our-wines-js": () => import("./../../../src/templates/our-wines.js" /* webpackChunkName: "component---src-templates-our-wines-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/static-page.js" /* webpackChunkName: "component---src-templates-static-page-js" */),
  "component---src-templates-visit-winery-js": () => import("./../../../src/templates/visit-winery.js" /* webpackChunkName: "component---src-templates-visit-winery-js" */),
  "component---src-templates-wine-club-js": () => import("./../../../src/templates/wine-club.js" /* webpackChunkName: "component---src-templates-wine-club-js" */),
  "component---src-templates-wine-finder-js": () => import("./../../../src/templates/wine-finder.js" /* webpackChunkName: "component---src-templates-wine-finder-js" */)
}

