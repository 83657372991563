import breakpoints from './breakpoints'
import theme from '@/theme'

export const mediaMax = Object.keys(breakpoints).map((key) => [key, breakpoints[key]]).reduce(
  (prev, [key, breakpoint]) => {
    prev[key] = `@media (max-width: ${breakpoint}px)`
    return prev
  },
  {}
)

export const mediaMin = Object.keys(breakpoints).map((key) => [key, breakpoints[key]]).reduce(
  (prev, [key, breakpoint]) => {
    prev[key] = `@media (min-width: ${breakpoint}px)`
    return prev
  },
  {}
)

export const mediaBetween = (a, b) => {
  if (!breakpoints[a] || !breakpoints[b]) throw new Error('mediaBetween: invalid breakpoint')
  if (breakpoints[a] < breakpoints[b]) {
    return `@media (min-width: ${breakpoints[a] + 1}px) and (max-width: ${breakpoints[b] - 1}px)`
  } else if (breakpoints[a] > breakpoints[b]) {
    return `@media (min-width: ${breakpoints[b] + 1}px) and (max-width: ${breakpoints[a] - 1}px)`
  } else {
    throw new Error('mediaBetween: a and b must be different')
  }
}

export const sectionTitle = () => {
  return `
    font-size: ${theme.fontSizes.title.lg};
    font-family: ${theme.fonts.serif};
    color: ${theme.colors.green};
    font-weight: bold;
    ${mediaMax.xs} {
      font-size: ${theme.fontSizes.title.xs};
    }
  `
}

export const sectionSubtitle = () => {
  return `
    font-size: ${theme.fontSizes.subtitle.lg};
    font-family: ${theme.fonts.sansSerif};
    color: ${theme.colors.green};
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    ${mediaMax.xs} {
      font-size: ${theme.fontSizes.subtitle.xs};
    }
  `
}

export const styledScrollbar = () => {
  return `
    scrollbar-color: ${theme.colors.green} ${theme.colors.grey};
    &::-webkit-scrollbar {
      width: 10px;
      height: auto;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.green};
    }
    &::-webkit-scrollbar-track {
      background-color: #0000001C;
    }
  `
}
