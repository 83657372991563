const breakpoints = {
  xxs: 300,
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1440,
  xxl: 1920
}

export default breakpoints
