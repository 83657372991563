import React from 'react'
import { RecoilRoot } from 'recoil'
import { ThemeProvider, Global } from '@emotion/react'
import theme from './src/theme'
import globalStyles from './src/utils/globalStyles'

export const wrapRootElement = ({ element }) => (
  <RecoilRoot>
    <ThemeProvider theme={ theme }>
      <Global styles={ globalStyles } />
      {element}
    </ThemeProvider>
  </RecoilRoot>
)
